import { Popover } from 'bootstrap';
import noUiSlider from 'nouislider';
import wNumb from 'wnumb';

export class MillesimesHelper {
    constructor() {
        this.selectedValues = new Set()

        this.rangeValues = { include: [],
                             exclude: [] }

        this.popovers = {
            include: new Popover($('#includeChoice')),
            exclude: new Popover($('#excludeChoice'))
        }

        for ( const [_, popover] of Object.entries(this.popovers) ) {
            popover.disable()
        }

        this.currentYear = new Date().getFullYear()
        this.nextYear    = this.currentYear + 1

        this.disabledReasons = {
            selectedRange: "Désélectionnez votre plage de millésimes pour pouvoir repasser en mode sélection",
            selectedValues: "Désélectionnez vos millésimes pour pouvoir passer en mode plage",
            includeLock: "Il est impossible de passer en mode sélection avec un millésime exclu",
            excludeLock: "Il est impossible d'exclure un millésime avec des millésimes sélectionnés"
        }
    }

    updateSelectedValues(values) {
        // Update set of values on select
        this.selectedValues = new Set(values)
    }

    disableExcludeDom(excludeDom) {
        if ($(excludeDom).find('select').length > 0) {
            // We are in select mode
            $(excludeDom).find('select')
                         .attr('disabled', 'disabled')
        } else {
            // We are in slider mode
            excludeDom.noUiSlider.disable()
        }

        // Disable switch button for exclude
        this.disableSwitchButton('exclude',
                                 'excludeLock')
    }

    enableExcludeDom(dom) {
        if ($(dom).find('select').length > 0) {
            // We are in select mode
            let selectDom = $(dom).find('select')

            if (selectDom.val().length === 0) {
                $(selectDom).removeAttr('disabled')
                this.enableSwitchButton('exclude')
            }


            // Reenable options
            this.enableOptions(selectDom)
        } else {
            // We are in slider mode
            dom.noUiSlider.enable()

            // Reset limits of dom
            this.rangeValues['exclude'].length ? this.resetLimits(dom)
                                               : this.resetSlider(dom)
        }
    }

    oppositeMode(mode) {
        return mode === 'include' ? 'exclude' : 'include'
    }

    enableOptions(selectDom) {
        selectDom.removeAttr('disabled')
        selectDom.find('option')
                 .removeAttr('disabled');
        selectDom.trigger('change')
    }

    disableOptions(values, selectDom) {
        // Disable all options outside of values
        values = values.map(value => parseInt(value, 10))

        // Build range values [v[0] + 1, v[1] - 1]
        values = Array(values[1] - values[0] - 1).fill()
                                                 .map((_, i) => values[0] + i + 1)
                                                 .map(String)
        selectDom.find('option')
                 .each(function(_, option) {
                    if (values.indexOf($(option).val()) === -1) {
                        $(option).attr('disabled', 'disabled')
                    } else {
                        $(option).removeAttr('disabled');
                    }
                 });

        selectDom.trigger('change.select2')
    }

    updateRangevalues(slider, mode, values = null) {
        // Get values
        values = values ? values : slider.noUiSlider.get()

        // Update values in class
        this.rangeValues[mode] = values.map(num => parseInt(num, 10))

        // Update hidden input field
        let name = mode === 'include' ? 'query[millesimes][inclusions][]'
                                      : 'query[millesimes][exclusions][]'

        $(`input[name='${name}']`).val(values.join(';'))

        // Replace upper text (see if we are at max and change text to currentYear+)
        this.replaceUpperText(slider, values[1])

        // Dispatch changes
        this.dispatchChangeToSlider(slider, values)
    }

    createSlider(sliderDom, mode) {
        if (sliderDom.noUiSlider) { return; }

        // Empty dom and create slider
        $(sliderDom).empty()
        $(sliderDom).addClass('ms-2 me-3 mx-sm-4')


        let start = this.rangeValues['include'].length > 0 ? this.rangeValues['include']
                                                           : [1970, this.currentYear + 1]

        let padding = mode === 'exclude' ? [1, 1] : [0 , 0]

        noUiSlider.create(sliderDom, {
            start: start,
            connect: true,
            step: 1,
            margin: 4,
            padding: padding,
            range: {
                min: 1970,
                max: this.currentYear + 1
            },
            ariaFormat: wNumb({decimals: 0}),
            format: {
                to: function(value) { return wNumb({ decimals: 0 }).to(value) },
                from: function(value) { return wNumb({ decimals: 0 }).from(value) }
            }
        })

        if ((mode === 'exclude') && (this.rangeValues['include'].length > 0)) {
            this.updateExcludeSliderBounds(start, sliderDom)
        }


        // Replace upper handle text for inclusion
        this.replaceUpperText(sliderDom, sliderDom.noUiSlider.get()[1])

        // Create hidden field to keep millesimes ranges
        this.createHiddenRange(sliderDom, mode)
    }

    createHiddenRange(dom, mode) {
        let name = mode === 'include' ? 'query[millesimes][inclusions][]'
                                      : 'query[millesimes][exclusions][]'
        $('<input>', {
            type: 'hidden',
            name: name
        }).appendTo(dom)
    }

    dispatchChangeToSlider(slider, values){
        let event = new Event('change')
        event.values = values
        slider.dispatchEvent(event)
    }

    updateExcludeSliderBounds(includeValues, excludeSlider) {
        // Do nothing if user set exclude bounds
        if (this.rangeValues['exclude'].length > 0) { return; }

        includeValues = includeValues.map(num => parseInt(num, 10))
        // Update slider bounds (range has to be inside include slider)
        let noUiSlider = excludeSlider.noUiSlider;

        // Get range values for exclude
        let newValues  = [includeValues[0] + 1,
                          includeValues[1] - 1 ]


        noUiSlider.updateOptions({
            start: newValues,
            margin: 2
        })
    }

    replaceUpperText(slider, upperValue) {
        if (upperValue == this.nextYear) {
            $(slider).find('.noUi-handle-upper').attr('aria-valuetext',
                                                       String(this.currentYear) + '+')
        }
    }

    hasOppositeSelectedValues(mode) {
        let oppositeMode = this.oppositeMode(mode)
        return (this.rangeValues[oppositeMode].length != 0) || (this.selectedValues.size != 0)
    }

    updateSliderBounds(slider, values, handle, mode) {
        if (!this.hasOppositeSelectedValues(mode)) {
            return;
        }

        values = values.map(num => parseInt(num, 10))
        mode === 'include' ? this.updateIncludeMargin(slider, values, handle)
                           : this.updateExcludeLimit(slider, values, handle)
    }

    updateExcludeLimit(slider, values, handle) {
        switch(handle) {
            case 0:
                slider.updateOptions({ limit: values[1] - this.rangeValues['include'][0] - 1 })
                break
            case 1:
                slider.updateOptions({ limit: this.rangeValues['include'][1] - 1 - values[0] })
                break
        }
    }

    updateIncludeMargin(slider, values, handle) {
        let rangeValues = [this.rangeValues['exclude'][0] || Math.min(...this.selectedValues),
                           this.rangeValues['exclude'][1] || Math.max(...this.selectedValues)]


        switch(handle) {
            case 0:
                slider.updateOptions({ margin: values[1] - rangeValues[0] + 1})
                break
            case 1:
                slider.updateOptions({ margin: rangeValues[1] - values[0] + 1 })
                break
        }
    }

    resetSlider(slider, mode) {
        let start = mode === 'include' ? [1970, this.currentYear + 1]
                                       : this.getExcludeDefaultRange();

        slider.noUiSlider.updateOptions({
            start: start,
            limit: null,
            margin: 4
        })

        this.rangeValues[mode] = []
        this.replaceUpperText(slider, start[1])
    }

    resetSelect(selectDom, mode) {
        selectDom.val(null).trigger('change')
        this.selectedValues = new Set()
    }

    resetLimits(slider) {
        slider.noUiSlider.updateOptions({
            limit: null,
            margin: 4
        })

        this.replaceUpperText(slider, this.rangeValues['include'][1])
    }

    switchButton(mode) {
        return $(`#${mode}Choice`)
    }

    getExcludeDefaultRange() {
        let rangeValues = this.rangeValues['include']

        let range = rangeValues.length > 0 ? [rangeValues[0] + 1, rangeValues[1] - 1]
                                           : [1970, this.currentYear]

        return range
    }

    disableSwitchButton(mode, reason, checkEnabled = false) {
        this.switchButton(mode).attr('disabled', 'disabled')

        if (!checkEnabled || !this.popovers[mode]._isEnabled) {
            this.popovers[mode]._config.content = this.disabledReasons[reason]
        }

        this.popovers[mode].enable()
    }

    enableSwitchButton(mode) {
        this.switchButton(mode).removeAttr('disabled')
        this.popovers[mode].disable()
    }
}