import {Controller} from '@hotwired/stimulus';
import { dispatchChanges } from '../../../javascript/plugins/useful_tools';
import { updateHeader, updateMobileHeader, disableTab, enableTab, getTabFor } from './helpers/queries';

export default class extends Controller {
    connect() {
        this.watchChanges()

        // Wait document to be loaded before initialize options
        $(() => {
            this.initializeSelectedOptions();
            this.initializeSelectedColors();
        })
    }

    initializeSelectedColors(){
        let _this = this;
        $('#collapseColor').find('input:checked').each(function(_){
            // Fake an event to update header
            let event = new Object()
            event.params = { headerId: 'headerColor',
                             textClass: '.header-text',
                             mode: 'include' }

            event.currentTarget = this.parentNode
            event.type = 'click'
            _this.updateHeader(event)
        })
    }

    initializeSelectedOptions() {
        // Update header for preselected options in edit mode
        $('select').each(function(_, selectDom) {
            if ($(selectDom).val().length > 0) {
                // Change for header (stimulus only works with dispatchEvent ? )
                $(selectDom).trigger('select2:select')

                // Go on right tab
                let mode = $(selectDom).data('queries--new-form-mode-param')
                getTabFor(selectDom, mode).trigger('click')

                $(selectDom).find('option:selected').each(function(_, selectOption) {
                    let event = new Event('change', { bubbles: true })
                    event.text = selectOption.text
                    selectDom.dispatchEvent(event)
                })
            }
        })
    }

    updateHeader(event) {
        let dom_header = $(`#${event.params.headerId}`)
        let mode = event.params.mode;
        let nSelected = this.nSelectedValues(event)

        // Get text
        let text = this.getText(event);
        updateHeader(dom_header,
                     text,
                     mode)

        updateMobileHeader(dom_header,
                           nSelected,
                           mode)
    }

    nSelectedValues(event) {
        let dom_body  = event.currentTarget
        let nSelected = 0

        if (event.type === 'click') {
            // We are on color, so we have to find checkbox checked
            // find checkbox
            let checkbox = $(dom_body).find('input')
            let name = checkbox.attr('name');
            nSelected = $(`[name='${name}']:checked`).length
        } else {
            // We select item so we have to know number of selected values
            let name = $(dom_body).attr('name')
            $(`select[name='${name}']`).each((_, dom) => {
                nSelected += $(dom).val().length
            })
        }

        return nSelected
    }

    getText(event) {
        let dom_body   = $(event.currentTarget)
        if (event.params.textClass) {
            return dom_body.find(event.params.textClass).text();
        } else if (event.params.assemblages) {
            let values = [...new Set([dom_body.attr('data-min'),
                                      dom_body.attr('data-max')])]
            return `${event.text} (${values.join('-')}%)`
        }
        else if (event.text) {
            return event.text
        }
    }

    watchChanges() {
        var doms_to_watch = {
            Contest: ['Taster'],
            WineType: [],
            Taster: ['Contest'],
            Country: ['Region', 'Appellation'],
            Region: ['Country', 'Appellation'],
            Appellation: ['Country', 'Region']
        }

        let _this = this;
        for ( const mode of ['include', 'exclude'] ){
            for (const key in doms_to_watch) {
                $(`#${mode}${key}`).on('select2:select select2:unselect', function(e) {
                    let dom_ids = doms_to_watch[key].map( suffix => [`#include${suffix}`,
                                                                     `#exclude${suffix}`] )
                                                    .flat();

                    let plural_key = t(key.toLowerCase(), { N: 2 }, 'en')
                    dispatchChanges(dom_ids,
                        $(this).val(),
                        `data-${mode}d-${plural_key}`);
                });
                this.toggleOppositeDom(mode, key)
            }
        }
    }

    toggleOppositeDom(mode, key) {
        let opposite = mode === 'include' ? 'exclude' : 'include'
        let selectDom = $(`#${mode}${key}`)

        $(`#${mode}${key}`).on('select2:select select2:unselect', function(event) {
            let oppositeSelectDom = $(`#${opposite}${key}`)
            if (!oppositeSelectDom.is('select')) { oppositeSelectDom = oppositeSelectDom.find('select') }

            switch(event.type) {
                case 'select2:select':
                    oppositeSelectDom.attr('disabled', 'disabled')
                    disableTab(oppositeSelectDom, opposite)
                    break
                case 'select2:unselect':
                    // Find select if we are not on select dom
                    if (!$(this).is('select')) { selectDom = $(this).find('select') }
                    if (selectDom.val().length === 0) {
                        oppositeSelectDom.removeAttr('disabled')
                        enableTab(oppositeSelectDom, opposite)
                    }
                    break
            }
        })
    }
}