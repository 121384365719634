import { Controller } from "@hotwired/stimulus"
import Lightpick from "lightpick"

export default class extends Controller {
    static targets = ['input']
    static values = {
        startDate: { type: String, default: '' },
        endDate: { type: String, default: '' }
    }

    connect() {
        const currentYear = new Date().getFullYear()
        this.picker = new Lightpick({
            field: this.inputTarget,
            singleDate: false,
            selectForward: true,
            startDate: this.startDateValue,
            endDate: this.endDateValue,
            maxDate: new Date(currentYear, 11, 31)
        });
    }
}