import {Controller} from '@hotwired/stimulus';
import { formatItem } from '../plugins/select2_format';

export default class extends Controller {
    static values = {
        placeholder: String,
        mode: { type: String, default: 'simple' },
        callbackUrl: { type: String, default: '' },
        formatWith: { type: String, default: '' },
        toggleIn: { type: String, default: '' },
        included: { type: Array, default: [] },
        excluded: { type: Array, default: [] },
        includeMode: { type: String, default: 'include' },
        maximumSelections: { type: Number, default: 0 }
    }
    static targets = ['select']

    connect() {
        this.createSelect();
        this.triggerChangeEvent();
        this.hideDropdownOnUnselect();
    }

    toggleOption(event) {
        let select_dom = $(event.params.toggleIn);
        let option = select_dom.find(` option[value='${event.id}']`);


        switch (event.selectType + ' ' + this.modeValue) {
            case 'select2:select simple':
                option.attr('disabled', 'disabled')
                break;
            case 'select2:unselect simple':
                option.removeAttr('disabled')
                break;
            default:
                console.log('unknown event')
        }

        select_dom.trigger('change')
    }

    createSelect() {
        let _this = this;
        switch(this.modeValue) {
            case 'simple':
                $(this.selectTarget).select2({
                    placeholder: this.placeholderValue,
                    language: 'fr',
                    sorter: function(results) { // Sort by disabled option (false)
                        results.sort(function(a, b) {
                            const isDisabled = (item) => item.element.hasAttribute('disabled')
                            return Number(isDisabled(a)) - Number(isDisabled(b))
                        });

                        return results
                    },
                    templateResult: function(item) {
                        if (!item.selected) {
                            return item.text
                        }
                    }
                 })
                break
            case 'ajax':
                // FIXME: Ajax select2
                let _this = this;
                $(this.selectTarget).select2({
                    ajax: {
                        url: _this.callbackUrlValue,
                        data: function (params) {
                            const [included, excluded] = _this.buildParams();
                            return {
                                args: {
                                    q: params.term,
                                    page: params.page || 1,
                                    mode: _this.includeModeValue,
                                },
                                included: included,
                                excluded: excluded
                            };
                        },
                        processResults: function (data) {
                            let selectedValues = this.$element.val();
                            data.items.results = data.items.results.filter(
                                (result) => !selectedValues.includes(String(result.id))
                            )

                            return data.items;
                        },
                        dataType: 'json',
                        delay: 250,
                    },
                    minimumInputLength: 3,
                    maximumSelectionLength: _this.maximumSelectionsValue,
                    placeholder: _this.placeholderValue,
                    templateResult: formatItem(_this.formatWithValue),
                    templateSelection: formatItem(_this.formatWithValue),
                    language: 'fr'
                });
                break
            default:
                console.log('unknown select2 mode')
        }

    }

    buildParams() {
        var params = [{}, {}]
        for ( const key of this.includedValue ) {
            params[0][key] = $(this.selectTarget).attr(`data-included-${key}`)
        }

        for ( const key of this.excludedValue ) {
            params[1][key] = $(this.selectTarget).attr(`data-excluded-${key}`)
        }

        return params;
    }

    triggerChangeEvent() {
        $(this.selectTarget).on('select2:select select2:unselect', function (e) {
            if (!e.params) { return; }

            let data = e.params.data;
            const event = new Event('change', { bubbles: true });

            // Put attributes for event
            event.selectType = e.type;
            event.text = data.text
            event.id = data.id

            this.dispatchEvent(event);
        });
    }

    hideDropdownOnUnselect() {
        // Patch github to hide dropdown on unselect
        // See: https://github.com/select2/select2/issues/3209#issuecomment-149663474
        $(this.selectTarget).on('select2:unselect', function(event) {
            if (!event.params.originalEvent) {
                return;
            }

            event.params.originalEvent.stopPropagation();
        });
    }
}