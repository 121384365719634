import { Base } from "./base_helper"

export class Sunburst extends Base {
    constructor(data, height, filename) {
        super(height, filename)
        // Set sunburst data
        this.data = [{
            type: 'sunburst',
            labels: data.labels,
            values: data.values,
            parents: data.parents,
            level: '',
            leaf: {
                opacity: data.leafOpacity || 1
            },
            marker: {
                line: {
                    width: 1,
                    color: '#fff'
                },
                colors: data.colors
            },
            branchvalues: 'total',
            hovertemplate: data.hovertemplate,
            showlegend: true
        }]
    }
}