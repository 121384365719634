import { Popover } from "bootstrap"

// Function to add or remove header in QueryBuilder blocks
export function fillTemplate(text, mode, swapIcon = false) {
    let content  = $('#templateHeader').clone()
                                       .prop('content')

    $(content).find('text').text(text)
    $(content).find('.fas').addClass( mode === 'include' ? 'c-vert' : 'c-rouge' )

    if (swapIcon) {
        $(content).find('.fas').removeClass('me-2')
                  .insertAfter($(content).find('text').addClass('me-1 fw-bold'))
    }

    return content
}

export function updateHeader(dom_header, text, mode) {
    let header_pills = dom_header.find(`.border-text:contains('${text}')`)
                                 .filter(function() { return $(this).text().trim() === text.trim() })

    if ( header_pills.length ) {
        header_pills.remove()
    } else {
        // Complete template
        content = fillTemplate(text, mode)
        dom_header.find('slot#querySlot').append(content);
    }
}

export function updateMobileHeader(dom_header, nSelected, mode) {
    let mobileSlot = dom_header.find('slot#queryMobileSlot')

    let headerPill = findPillsFor(dom_header, mode, 'slot#queryMobileSlot')
    if ( nSelected ) {
        if (headerPill.length) {
            headerPill.find('text').text(nSelected)
        } else {
            content = fillTemplate(nSelected, mode, swapIcon = true)
            mobileSlot.append(content)
        }
    } else {
        headerPill.remove()
    }

}

export function findPillsFor(dom_header, mode, slot = 'slot#querySlot') {
    const color_class = mode === 'include' ? '.c-vert' : '.c-rouge'

    return dom_header.find(slot)
                     .find(`.border-text:has(${color_class})`)
}

export function getTabFor(dom, mode) {
    return $(dom).closest('.accordion-body') // Include/Exclude and tabs are in an accordion body
                 .find("[data-controller='queries--tabs']") // Find tabs by data controller
                 .find(`[data-queries--tabs-target="${mode}Tab"]`)
}

export function disableTab(dom, mode) {
    let tabToDisable = getTabFor(dom, mode)
    tabToDisable.addClass('inactive')

    let popover = Popover.getInstance(tabToDisable)
    popover.enable()
}

export function enableTab(dom, mode) {
    let tabToEnable = getTabFor(dom, mode)
    tabToEnable.removeClass('inactive')

    let popover = Popover.getInstance(tabToEnable)
    popover.disable()
}