import { Base } from "./base_helper"

export class Gauge extends Base {
    constructor(data, filename) {
        super(750, filename)

        this.data = [{
            type: 'indicator',
            mode: 'gauge',
            title: {
                text: 'Équilibre'
            },
            steps: data.steps
        }]
    }
}