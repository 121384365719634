import { Base } from "./base_helper"

export class Pie extends Base {
    constructor(data, filename) {
        super(500, filename)
        this.layout.content.height = 500
        this.layout.content.margin = { l: 0, r: 0, t: 60, b: 0 },
        // Set sunburst data
        this.data = [{
            type: 'pie',
            labels: data.labels,
            values: data.values,
            marker: {
                line: {
                    width: 1,
                    color: '#fff'
                },
                colors: data.colors
            },
            hole: .4,
            showlegend: false,
            textinfo: 'label',
            sort: false
        }]
    }
}