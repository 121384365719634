import { sum, dispatchChanges } from "../../../../javascript/plugins/useful_tools";
import noUiSlider from 'nouislider';
import wNumb from 'wnumb';
import {Popover} from 'bootstrap';
import * as queriesHelper from './queries'

export class AssemblagesHelper {
    constructor(mode, addButton) {
        this.minBounds = []
        this.maxBounds = []
        this.mode = mode

        // Init popover on init button
        this.addButton  = addButton
        this.btnPopover = new Popover(this.addButton)
    }

    rowTemplate() {
        let rowTemplate = $(`.assemblage-${this.mode}`).first()
                                                       .clone()
                                                       .addClass('mt-4 mt-sm-2');

        // Empty select for edit mode
        rowTemplate.find('select').empty()

        // Empty second col of row and append div with slider target
        rowTemplate.find('.col-12')
                   .eq(1)
                   .empty()
                   .append('<div data-queries--assemblages-target="slider" class="ms-2 me-3 me-sm-0"><div/>')

        return rowTemplate
    }

    initNewSlider(slider_dom, minPc, maxPc) {
        // Create slider but disable it (until a cepage is selected)
        this.createSlider(slider_dom, minPc, maxPc)
        this.updateSelectValue(slider_dom)
        slider_dom.noUiSlider.disable()
    }

    dispatchChangesForSlider(values, slider, sliders) {
        let targetIdx = sliders.indexOf(slider)

        // Update minBounds for this slider
        this.minBounds[targetIdx] = parseInt(values[0])

        // If we are in include mode, update upper bounds for other sliders
        if (this.mode === 'include') {
            let sMinBounds = this.sumLowerBounds()
            if (sMinBounds === 100) {
                this.disableAddButton('max')
                this.disableSelects()
                this.triggerIncludeChange({ sMinBounds: 99 }, 'include.cepage:max')
            } else {
                this.enableAddButton()
                this.enableExclude()
                this.triggerIncludeChange({ sMinBounds: sMinBounds })
            }

            this.updateUpperBounds(slider, sliders, sMinBounds)
        }

        // Update Header
        this.updateHeader(slider, values)

        // Update select attributes
        this.updateSelectValue(slider)
    }

    dispatchChangesForSelect(event, slider_dom, sliders) {
        let event_type = event.type
        let nSliders = sliders.length // Number of sliders in same mode (include or exclude)

        // If select push init values to min and max bound
        // Check if event has params. If not, select was trigger progamatically
        if (event_type === 'select2:select' && event.params) {
            this.minBounds.push(1)
            this.maxBounds.push(100)
        }

        // Get all selected cepages
        let cepages = this.getCepagesIds(this.mode)

        // Dispatch changes to all select which have to know
        // about included and
        dispatchChanges($("select[id*='Cepage']"),
                        cepages.join(','),
                        `data-${this.mode}d-cepages`)

        if (event_type === 'select2:select') {
            // Update select value if a cepage is selected and enable
            // add row button
            this.updateSelectValue(slider_dom)
            this.enableAddButton();

            // Disable input search
            this.disableInputSearch($(event.currentTarget).next('.select2'))

            // Update bounds
            let sMinBounds = this.mode === 'include' ? sum(this.minBounds)
                                                     : this.includeMinBounds

            this.triggerIncludeChange({ sMinBounds: sMinBounds })
            this.updateUpperBounds(null, sliders, sMinBounds);
        } else if (event_type === 'select2:unselect') {
            // Count number of sliders in same mode (include or exclude)

            if ( nSliders > 1 ) {
                // If more than one select remove row and update min and max bounds
                let targetIdx = sliders.indexOf(slider_dom)

                // Update upper bounds
                let sMinBounds = sum(this.minBounds) - this.minBounds[targetIdx]
                this.updateUpperBounds(slider_dom, sliders, sMinBounds)

                // Trigger change
                this.triggerIncludeChange({ sMinBounds: sMinBounds })

                // Enable add button
                this.enableAddButton()

                // Remove row (delay to remove pills in header)
                let _this = this
                setTimeout( function() {
                    _this.removeRow(event.currentTarget)
                    _this.updateBounds(targetIdx)
                })
            } else {
                // If it's only select reset bounds
                this.enableInputSearch($(event.currentTarget).next('.select2'))

                // Disable add button
                this.disableAddButton()

                // Reset slider_dom
                this.resetSlider(slider_dom)

                // Reset min and max bounds
                this.resetBounds()

                // Disable slider
                slider_dom.noUiSlider.disable()

                // Trigger change
                this.triggerIncludeChange({ sMinBounds: 0 })

                // Update select value after delay (delay to remove pills in header)
                let _this = this;
                setTimeout(function() {
                    _this.updateSelectValue(slider_dom)
                })
            }
            this.enableExclude()
        }
    }

    getCepagesIds(mode) {
        let cepages = []
        $(`select[id^='${mode}Cepage`).each((_, dom) => {
            let value = $(dom).val()
            if (value.length > 0) {
                cepages.push(value[0].split(';')[0])
            }
        })

        return cepages
    }

    resetSlider(slider) {
        let noUiSlider = slider.noUiSlider
        noUiSlider.updateOptions({
            start: [1, 100],
            padding: [0, 0]
        })
        noUiSlider.disable()
    }

    resetBounds() {
        this.minBounds = []
        this.maxBounds = []
    }

    updateBounds(targetIdx) {
        // Remove min and max bound at target index
        this.minBounds.splice(targetIdx, 1)
        this.maxBounds.splice(targetIdx, 1)
    }

    updateUpperBounds(excludeSlider, sliders, sMinBounds) {
        // Update upper bounds of sliders which are not current slider
        for (const [targetIdx, slider] of sliders.entries()) {
            if ((slider === excludeSlider) || (!this.hasOptionSelected(slider))) {
                continue
            }

            let noUiSlider = slider.noUiSlider;
            const minBound   = this.mode === 'include' ? parseInt(noUiSlider.get()[0])
                                                     : 0

            noUiSlider.updateOptions({
                padding: [0, sMinBounds - minBound],
                start: [this.minBounds[targetIdx], this.maxBounds[targetIdx]]
            })

            this.updateHeader(slider, noUiSlider.get())

            // Update select min-max value
            this.updateSelectValue(slider);
        }
    }

    updateSelectValue(slider) {
        let select = this.getConnectedSelect(slider)
        let selected_opt = select.find(':selected')
        let values = slider.noUiSlider.get();

        // Update select and slider data attributes
        select.attr('data-min', values[0])
        select.attr('data-max', values[1])

        if (selected_opt.length > 0) {
            $(slider).attr('data-cepage', selected_opt.text())

            // Update value for option
            let cepage_id = selected_opt.attr('value').split(';')[0]
            let new_value = `${cepage_id};${values[0]};${values[1]}`
            selected_opt.attr('value', new_value)
            // Enable slider
            slider.noUiSlider.enable()
        }
    }

    sumLowerBounds() {
        return sum(this.minBounds)
    }

    createSlider(slider_dom, min, max) {
        // Create a new slider with a padding at the upper bound
        noUiSlider.create(slider_dom, {
            start: [min || 1, max || 100],
            connect: true,
            step: 1,
            range: {
                min: 1,
                max: 100
            },
            ariaFormat: wNumb({decimals: 0, suffix: '%'}),
            format: {
                to: function(value) { return wNumb({ decimals: 0 }).to(value) },
                from: function(value) { return wNumb({ decimals: 0 }).from(value) }
            }
        })
    }

    updateHeader(slider, values) {
        let dom_header = $('#headerAssemblage')
        let cepage = $(slider).attr('data-cepage')

        values = [...new Set(values)]
        let newText = `${cepage} (${values.join('-')}%)`

        dom_header.find(`text:contains('${cepage}')`)
                  .text(newText)
    }

    removeRow(select_dom) {
        // Disable select2
        $(select_dom).select2('destroy')

        // remove row
        $(select_dom).closest('.row').remove()
    }

    getConnectedSelect(slider) {
        return $(slider).parent()
                        .closest('.row')
                        .find('select')
    }

    triggerIncludeChange(data, event = 'include.cepage:change') {
        if (this.mode === 'include') {
            $(document).trigger(event,
                                data)
        }
    }

    enableAddButton() {
        $(this.addButton).removeAttr('disabled')
        this.btnPopover.disable()
    }

    disableAddButton(why = 'no cepage') {
        $(this.addButton).attr('disabled', 'disabled')

        switch(why) {
            case 'no cepage':
                this.btnPopover._config.content = 'Ajoutez un cépage avant de pouvoir en ajouter un autre'
                break
            case 'max':
                this.btnPopover._config.content = "L'assemblage est déjà composé à 100%"
                break
            case 'max include':
                this.btnPopover._config.content  = "Impossible d'exclure des cépages: vous avez un assemblage composé à 100%"
        }

        this.btnPopover.enable()
    }

    enableExclude() {
        $("#cardExcludeCepage").find('select')
                               .removeAttr('disabled')

        // Redisplay headers for exclude
        queriesHelper.findPillsFor($('#headerAssemblage'), 'exclude')
                     .removeClass('d-none')
    }

    disableSelects() {
        // Disable exclude selects
        $("#cardExcludeCepage").find('select')
                               .attr('disabled', 'disabled')

        // Remove headers for exclude
        queriesHelper.findPillsFor($('#headerAssemblage'), 'exclude')
                     .addClass('d-none')

        // Disable include with no values
        $("#cardIncludeCepage").find('select')
                               .not(':has(option:selected)')
                               .closest('.row')
                               .remove()

    }

    hasOptionSelected(slider) {
        let select_dom = this.getConnectedSelect(slider)

        return select_dom.val().length > 0
    }

    enableInputSearch(select2_container) {
        let input_search = select2_container.find('.select2-search.select2-search--inline')
        input_search.removeClass('d-none')
    }

    disableInputSearch(select2_container) {
        let input_search = select2_container.find('.select2-search.select2-search--inline')
        input_search.addClass('d-none')
    }
}
