import {Controller} from '@hotwired/stimulus';
import { FormCache } from '../helpers/cache_helper';

export default class extends Controller {
    static values = {
        page: String,
        resetable: { type: Boolean, default: false }
    }

    static targets = ['deleteBtn']

    connect() {
        if (this.hasDeleteBtnTarget) {
            this.deleteBtnTargets.forEach(btn => {
                this.setUrlParamCache(btn)
            })
        }

        // FIXME: Maybe there is a best solution to check if a user is reloaded
        // a page which is resetable (i.e. a page which has filters cache on it )
        var _this = this;
        $(window).on('load', function() {
            if ((window.location.pathname == sessionStorage['lastVisited']) && (_this.resetableValue)) {
                sessionStorage.clear();
            }
        })
        $(window).on('unload turbo:visit', function(event) {
            let pathname = event.detail ? new URL(event.detail.url).pathname
                                        : window.location.pathname
            sessionStorage.setItem('lastVisited', pathname)
        })

    }

    cache_query(event) {
        let formCache = new FormCache(event.params.pageKey);
        let cache_query = formCache.storage.find(field => field.name === 'query');
        var query = $(event.currentTarget).find('input[name^=query]').val()

        if (cache_query) {
            cache_query['value'] = query
        }
        else {
            formCache.set('query', query)
        }

        formCache.store();
    }

    setPostBtnCache(event) {
        // Prevent submit for button
        event.preventDefault()

        let btn = event.currentTarget;
        this.set_form_cache(btn.form, $(btn).data('cacheKey'))

        // Submit form
        btn.form.submit()
    }

    setDeleteBtnCache() {

    }

    set_form_cache(form, cacheKey) {
        let formCache = new FormCache(cacheKey)

        formCache.storage.forEach(field => {
            let input = $(`<input type=hidden name='${field.name}' value='${field.value}'>`)
            $(form).append(input)
        })
    }

    setUrlParamCache(btn) {
        let formCache = new FormCache($(btn).data('cacheKey'))

        var url = new URL(btn.href)
        formCache.storage.forEach(field => {
            url.searchParams.append(field.name, field.value)
        })

        btn.href = url
    }

    resetFilters(event) {
        if (!event.ctrlKey) {
            sessionStorage.clear()
        }

    }
}