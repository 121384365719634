import { Base } from "./base_helper"

export class Radar extends Base {
    constructor(data, filename) {
        super(750, filename)

        this.data = [{
            type: 'scatterpolar',
            mode: 'markers',
            line: {
                shape: 'spline'
            },
            r: [...data.r, data.r[0]],
            theta: this.changeLabels(data.theta),
            fill: 'toself',
            opacity: 0.75,
            marker: {
                opacity: 0
            },
            showlegend: false,
            hoverinfo: 'none'
            //tickmode: 'ar'
        }]



        this.layout.add({
            polar: {
                radialaxis: {
                    visible: true,
                    showticklabels: false,
                    ticks: "",
                    //nticks: 6,
                    tick0: 1.75,
                    dtick: 0.625,
                    showline: false,
                    range: [1.5, 4.25]
                },
                gridshape: 'circular',
                bgcolor: '#f1f1f1',
                domain: {
                    x: [0.1, 0.9],
                    y: [0.1, 0.9]
                }
            },
        });
    }

    changeLabels(labels) {
        labels = labels.map((label) => label.replace(/\/.*/, '')
                                            .trim())

        return [...labels, labels[0]]
    }

    add_default({ mean = 3, color = '#a22716',
                  dash = 'longdashdot', fill = 'none',
                  opacity = 1, width = 1.25 } = {}) {
        this.data.push({
            type: 'scatterpolar',
            mode: 'lines',
            line: {
                shape: 'spline',
                dash: dash,
                color: color,
                width: width
            },
            opacity: opacity,
            fill: fill,
            r: new Array(this.data[0].r.length).fill(mean),
            theta: this.data[0].theta,
            showlegend: false,
            hoverinfo: 'none'
        })
    }

    fillColor(color, index = 0) {
        this.data[index].fillcolor = color
    }

    boldLabels(index = 0) {
        this.data[index].theta = this.data[index].theta.map(label => `<b>${label}</b>`)
    }
}