const animateCSS = (element, animation, duration = '1s') =>
  // We create a Promise and return it
  new Promise((resolve, reject) => {
    const prefix = 'animate__'
    const animationName = `${prefix}${animation}`;

    const node = $(element);
    node.addClass(`${prefix}animated ${animationName}`);
    node.css('--animate-duration', duration);

    // When the animation ends, we clean the classes and resolve the Promise
    function handleAnimationEnd(event) {
      event.stopPropagation();
      node.removeClass(`${prefix}animated ${animationName}`);
      resolve('Animation ended');
    }

    node.one('animationend', handleAnimationEnd);
  });


export { animateCSS };