import {Controller} from '@hotwired/stimulus';
import { AxiosRequest } from '../helpers/axios_helper';

export default class extends Controller {
    static targets = ['textFR']

    connect() {
    }

    translate(event) {
        let textToTranslate = $('#deepl-fr').val()
        let targetLang = event.params.targetLang

        let axiosRequester = new AxiosRequest(
            '/api/deepl/translate',
            'post',
            {
                text: textToTranslate,
                target_lang: targetLang.toUpperCase(),
                source_lang: 'FR'
            },
            {
                'Content-Type': 'application/json'
            }
        )


        axiosRequester.sendHtml()
                      .then(data => {
          $(`#deepl-${targetLang}`).val(data.translated_text)
        })

    }
}