// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "./controllers";

import jquery from "jquery";
import 'bootstrap';

window.$ = jquery;
window.jQuery = jquery;

// Select2
import select2 from 'select2';
select2($);

import initToastr from './plugins/toastr';
initToastr();

import { initSweetAlert, setConfirmDialog } from './plugins/sweet_alert';
initSweetAlert();
setConfirmDialog();


// LOAD translations
import initFrenchkiss from "./plugins/frenchkiss";
import { Popover } from "bootstrap";
initFrenchkiss();

// Hide popovers after 1s
$(window).on('load turbo:load', () => {
    $("[data-bs-toggle='popover'").on('shown.bs.popover', function() {
        let element = this
        setTimeout(function() {
            let popover = Popover.getInstance(element)
            popover.hide();
        }, 1000);
    })
})