import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['label', 'input']
    static values = {
        filename: { type: String, default: '' }
    }

    connect() {
        let form = $(this.labelTarget).closest('form')
        let inputTarget = this.inputTarget
        let filename = this.filenameValue

        form.find("[type='submit']").on('click', function(e) {
            if (!inputTarget.reportValidity()) {
                toastr.error(`Le ${filename.toLowerCase()} est requis pour pouvoir enregistrer`)
            }
        })
    }

    display_filename(event) {
        let filename = event.target
                            .value
                            .split('\\')
                            .pop()
        $(this.labelTarget).addClass('selected')
                           .html(filename)
    }
}