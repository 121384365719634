import Swal from 'sweetalert2';

export function initSweetAlert() {
    window.Swal = Swal;
}

export function setConfirmDialog() {
    Turbo.setConfirmMethod((message, _element) => {
        return Swal.fire({
            title: message,
            showCancelButton: true,
            confirmButtonText: 'Oui',
            confirmButtonColor: 'var(--bs-primary, #3085d6)',
            cancelButtonText: 'Non',
            allowOutsideClick: false,
            allowEscapeKey: false,
        }).then((result) => {
            return result.isConfirmed;
        });
    });
};