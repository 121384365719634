import {Controller} from '@hotwired/stimulus';
import { uid, dispatchChanges } from '../../../javascript/plugins/useful_tools'
import { AssemblagesHelper } from './helpers/assemblages';

export default class extends Controller {
    static targets = ['slider', 'btnPlus']

    static values = {
        mode: { type: String, default: 'include' }
    }

    initialize() {
        // Get helper for assemblages
        this.helper = new AssemblagesHelper(this.modeValue,
                                            this.btnPlusTarget)

        // Get a row template
        this.rowTemplate = this.helper.rowTemplate();
    }

    connect() {
        // Dispatch changes if edit mode
        this.sliderTargets.forEach(slider => {
            let minPc = $(slider).data('min-pc')
            let maxPc = $(slider).data('max-pc')

            if (minPc && maxPc) {
                // Dispatch changes when document is loaded
                $(() => {
                    this.helper.dispatchChangesForSlider([$(slider).data('min-pc'), $(slider).data('max-pc')],
                                                          slider,
                                                          this.sliderTargets)
                });
            }
        });
    }

    sliderTargetConnected(slider) {
        let minPc = $(slider).data('min-pc')
        let maxPc = $(slider).data('max-pc')

        this.helper.initNewSlider(slider,
                                  minPc,
                                  maxPc)

        // Initialize bounds for edit mode
        if (minPc && maxPc) {
            let targetIdx = this.sliderTargets.indexOf(slider)

            this.helper.minBounds[targetIdx] = minPc
            this.helper.maxBounds[targetIdx] = maxPc
        }

        // Dispatch all included and excluded cepages to new slider
        let select_dom = this.helper.getConnectedSelect(slider)
        for (const mode of ['include', 'exclude']) {
            let cepages = this.helper.getCepagesIds(mode)
            dispatchChanges([select_dom],
                            cepages.join(','),
                            `data-${mode}d-cepages`)
        }


        // Watch changes on select
        this.watchChangesForSelect(slider)

        // Watch changes on slider for other sliders
        this.watchChangesForSlider(slider)

        // If mode is exclude, listen includes event to update max bounds
        if (this.modeValue === 'exclude') {
            this.listenIncludes()
        }
    }

    watchChangesForSelect(slider) {
        const helper = this.helper
        let select_dom = helper.getConnectedSelect(slider)
        let _this = this;

        select_dom.on('select2:select select2:unselect', function(e) {
            helper.dispatchChangesForSelect(e,
                                            slider,
                                            _this.sliderTargets)
        });
    }

    watchChangesForSlider(slider) {
        let helper = this.helper
        let _this  = this

        slider.noUiSlider.on('slide', function(values){
            helper.dispatchChangesForSlider(values, slider, _this.sliderTargets)
        });

        slider.noUiSlider.on('end', function(values, handle){
            if (handle === 1) {
                let targetIdx = _this.sliderTargets.indexOf(slider)
                helper.maxBounds[targetIdx] = parseInt(values[1])
            }
        });
    }

    listenIncludes() {
        let _this = this;
        let helper = this.helper;

        $(document).on('include.cepage:change', function(_, data) {
            helper.includeMinBounds = data.sMinBounds
            helper.updateUpperBounds(null,
                                     _this.sliderTargets,
                                     data.sMinBounds)

            if (_this.sliderTargets.every((slider) => helper.hasOptionSelected(slider))) {
                helper.enableAddButton()
            } else {
                helper.disableAddButton()
            }
        });

        $(document).on('include.cepage:max', function(_, data) {
            _this.helper.disableAddButton('max include')
            _this.helper.updateUpperBounds(null, _this.sliderTargets, data.sMinBounds)
        });
    }

    addRow(event) {
        if ($(this.btnPlusTarget).attr('disabled')) {
            return;
        }

        let newRow = this.rowTemplate.clone()
        newRow.find('select')
              .attr('id', `${this.modeValue}Cepage${uid()}`)


        $(this.btnPlusTarget).parent()
                             .before(newRow);
        this.helper.disableAddButton()
    }
}