import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['message']

    connect() {
        this.messageTargets.forEach(element => {
            this.toaster_notification(element.dataset)
        });
    }

    toaster_notification(dataset) {
        switch(dataset.type) {
            case 'danger':
            case 'alert':
            case 'error':
                toastr.error(dataset.msg)
                break
            case 'warning':
                toastr.warning(dataset.msg)
                break
            case 'success':
                toastr.success(dataset.msg)
                break
        }
    }
}
