import {Controller} from '@hotwired/stimulus';
import * as queriesHelper from './helpers/queries';
import { MillesimesHelper } from './helpers/millesimes';

export default class extends Controller {
    static targets = [ 'choiceDom', 'choice' ]

    initialize() {
        this.dom_header = $('#headerMillesime')
        this.helper = new MillesimesHelper()

        this.enumMode = {
            include: 0,
            exclude: 1
        }

        this.currentChoice = { include: 'select',
                               exclude: 'select' }

        this.watchChangesForSelect()
    }

    connect() {
        let helper = this.helper

        $(this.choiceDomTargets).each((_, dom) => {
            // Get condition and mode
            var condition = $(dom).data('condition')
            const mode = $(dom).data('queries--millesimes-mode-param')

            if (condition) {
                if (condition.constraint.includes('between')) {
                    // User choose a range of values so switch to slider
                    // Switch select to slider
                    this.switchToSlider(dom, mode)

                    if (condition['values'].length === 1) {
                        // User choose upper than a value so add artificial bound at
                        // the end
                        condition['values'].push(String(helper.nextYear))
                    }

                    // Update start for slider
                    dom.noUiSlider
                       .updateOptions({ start: condition['values'] })

                    // Update range values
                    helper.updateRangevalues(dom,
                                             mode,
                                             condition['values'])

                    // Disable switch button
                    helper.disableSwitchButton(mode,
                                              'selectedRange')
                }
                else {
                    // Update selected values
                    this.helper.updateSelectedValues(condition.values)
                }
            }

            // Disable options if opposite choice is slider
            if (this.oppositeChoice(mode) === 'range') {
                this.helper.disableOptions(this.helper
                                               .rangeValues[this.helper.oppositeMode(mode)],
                                           $(dom))

            }
        })
    }

    switchToSelect(dom, mode) {
        // If no UI slider we are already on select
        if (!dom.noUiSlider) { return; }
        // First remove slider
        dom.noUiSlider.destroy()
        $(dom).removeClass('ms-2 me-3 mx-sm-4')

        // Get select Template and append it to Dom
        let selectTemplate = $(`#${mode}SelectMillesime`).clone()
                                                         .prop('content');

        $(dom).append(selectTemplate)

        this.changeButton(this.helper.switchButton(mode),
                          'range')

        // Change choice
        this.currentChoice[mode] = 'select'
    }

    changeChoiceDom(event) {
        // If btn disabled do nothing
        if ($(event.currentTarget).attr('disabled')) { return; }

        let choice = $(event.currentTarget).data('choice')
        let mode = event.currentTarget.id.replace('Choice', '')
        let targetIdx = this.enumMode[mode]
        let choiceDom = this.choiceDomTargets[targetIdx]

        switch(choice) {
            case 'select':
                this.switchToSlider(choiceDom, mode)
                break
            case 'range':
                // Create select
                this.switchToSelect(choiceDom, mode)
                break
        }
    }

    switchToSlider(dom, mode) {
        this.createSlider(mode)
        this.watchChangesForSlider(dom, mode)
        this.changeButton(this.helper.switchButton(mode),
                          'select')

        this.currentChoice[mode] = 'range'
    }

    oppositeChoice(mode) {
        return this.currentChoice[this.helper.oppositeMode(mode)]
    }

    oppositeDom(mode) {
        return this.choiceDomTargets[this.enumMode[this.helper.oppositeMode(mode)]]
    }

    reset(event) {
        let mode = event.params.mode
        let idx = this.enumMode[mode]
        let choiceDom = this.choiceDomTargets[idx]
        const helper = this.helper

        if (this.currentChoice[mode] === 'select') {
            let selectDom = $(choiceDom).find('select')

            // If select is disabled, or no value selected, we cannot reset so return null
            if ( $(selectDom).attr('disabled') || $(selectDom).val().length === 0  ) { return; }

            // Clear all selected values
            helper.resetSelect(selectDom, mode)
        } else {
            if ($(choiceDom).attr('disabled') || helper.rangeValues[mode].length === 0) { return; }

            helper.resetSlider(this.choiceDomTargets[idx], mode)
        }

        // Reenable include select if no selected values in exclude
        this.reenableOppositeDom(mode)

        // Remove pills
        queriesHelper.findPillsFor(this.dom_header, mode)
                     .remove()
        queriesHelper.findPillsFor(this.dom_header, mode, 'slot#queryMobileSlot')
                     .remove()
    }

    changeButton(btn, choice) {
        switch(choice) {
            case 'select':
                $(btn).data('choice', 'range')
                $(btn).find('.fas').toggleClass('fa-slider fa-hand-pointer')
                break
            case 'range':
                $(btn).data('choice', 'select')
                $(btn).find('.fas').toggleClass('fa-slider fa-hand-pointer')
                break
        }
    }

    createSlider(mode) {
        let targetIdx = this.enumMode[mode]
        this.helper.createSlider(this.choiceDomTargets[targetIdx], mode)
    }

    watchChangesForSelect(modes = ['include', 'exclude']) {
        let helper = this.helper
        let _this  = this;
        for (const mode of modes) {
            $(`#${mode}Millesime`).on('select2:select select2:unselect', function(e) {
                helper.updateSelectedValues($(this).find('select').val())

                // Enable/Disable switch button and opposite(Select/Slider)
                if ( $(this).find('select').val().length > 0  )
                {
                    // Disable switch until all values are deselected
                    helper.disableSwitchButton(mode,
                                               'selectedValues')

                    if ( mode === 'include' ){
                        // If we select include values, we cannot exclude (select or range)
                        helper.disableExcludeDom(_this.oppositeDom(mode));
                    } else {
                        // If we select exclude values, we cannot select include value but
                        // we can choose a range to include
                        // Example (we want to exclude 1997 and 2000 but we want all millesime between
                        // 1980 and 2005 )
                        _this.disableIncludeSelect()
                    }
                } else {
                    // No values are selected (equivalent to reset method)
                    // Enable switch
                    _this.reenableOppositeDom(mode)
                }
            });
        }

    }

    reenableOppositeDom(mode){
        if (mode === 'include'){
            // If we have no include values, we can exclude again
            this.helper.enableExcludeDom(this.oppositeDom(mode))
        } else {
            this.enableIncludeSelect()
        }

        // Enable to switch if no selected values in opposite choice
        if (!this.helper.hasOppositeSelectedValues(mode) || mode === 'exclude' ) {
            this.helper.enableSwitchButton(mode)
        }
    }

    watchChangesForSlider(slider, mode) {
        let helper = this.helper
        let _this  = this

        slider.noUiSlider.on('slide', function(values) {
            helper.updateRangevalues(slider, mode)

            // Update slider bound for exclude if no range selected in slider
            if (_this.currentChoice['exclude'] === 'range') {
                helper.updateExcludeSliderBounds(values,
                                                 _this.choiceDomTargets[1])
            }
        });

        slider.noUiSlider.on('start', function(values, handle) {
            // Update Bounds for opposite slider
            helper.updateSliderBounds(this, values, handle, mode)

            // Update range values
            helper.updateRangevalues(slider, mode)

            // Disable switch button
            helper.disableSwitchButton(mode,
                                       'selectedRange',
                                       true)

            // If mode is exclude disable include and switch to range
            if ((mode === 'exclude')){
               _this.disableIncludeSelect()
            }
        });

        slider.noUiSlider.on('change', function(values) {
            helper.updateRangevalues(slider, mode)

            if ((mode === 'exclude')){
                _this.disableIncludeSelect()
            }

            // Disable options in other select
            if (_this.oppositeChoice(mode) === 'select' ) {
                let selectDom = $(_this.oppositeDom(mode)).find('select')

                helper.enableOptions(selectDom)
                helper.disableOptions(values,
                                      selectDom)
            }
        });
    }


    enableIncludeSelect() {
        // Switch include select if we can, otherwise reset limits of slider
        if (this.helper.rangeValues['include'].length === 0) {
            // If user did not select range values in include we can switch to select
            this.switchToSelect(this.choiceDomTargets[0],
                                'include')

            // Reenable switch button
            this.helper.enableSwitchButton('include')
        } else {
            // Otherwise (user select include range) we can't switch to select. We just reset limits
            // of slider
            this.helper.resetLimits(this.choiceDomTargets[0], 'include')
            this.helper.disableSwitchButton('include',
                                            'selectedRange')
        }
    }

    disableIncludeSelect() {
        if (this.currentChoice['include'] === 'select') {
            // We switch include mode to range slider
            this.switchToSlider(this.choiceDomTargets[0],
                                'include')
        }

        this.helper.disableSwitchButton('include',
                                        'includeLock')
    }

    updateHeaderForSlider(text, mode) {
        let header_range = queriesHelper.findPillsFor(this.dom_header, mode)
        if (header_range.length > 0) {
            header_range.find('text').text(text)
        } else {
            let content = queriesHelper.fillTemplate(text, mode)
            this.dom_header.find('slot#querySlot').append(content)
        }
    }

    updateSliderBounds(slider, mode) {
        if (mode === 'include') {
            let values = slider.noUiSlider.get().map(num => parseInt(num, 10))

            if (this.currentChoice['exclude'] === 'range') {
                this.helper.updateExcludeSliderBounds(values,
                                                      this.choiceDomTargets[1])
            }
        }
    }

    updateHeader(event) {
        let mode = event.params.mode;

        // Get text
        let text = this.getText(event, mode)
        let nSelected = this.getNSelected(event, mode)

        queriesHelper.updateMobileHeader(this.dom_header, nSelected, mode)
        if (!text) { return; } // FIXME: handle bubbles of select2 dom

        if (this.currentChoice[mode] === 'select') {
            queriesHelper.updateHeader(this.dom_header,
                                       text,
                                       mode)
        } else {
            this.updateHeaderForSlider(text, mode)
        }
    }

    getNSelected(event, mode) {
        switch(this.currentChoice[mode]) {
            case 'select':
                return $(event.currentTarget).find('select')
                                             .val()
                                             .length
            case 'range':
                let values = [...new Set(event.values)]
                if (values[1] == this.helper.nextYear) {
                    let nMillesimes = this.helper.currentYear - parseInt(values[0], 10)
                    return `${nMillesimes}+`
                } else {
                    return  parseInt(values[1], 10) - parseInt(values[0], 10)
                }

        }
    }

    getText(event, mode) {
        switch(this.currentChoice[mode]) {
            case 'select':
                return event.text
            case 'range':
                let values = [...new Set(event.values)]
                let yearP  = this.helper.currentYear + 1
                return values.join(' - ').replace(String(yearP),
                                                  `${yearP - 1}+`);
        }
    }
}