import { Controller } from "@hotwired/stimulus"
import { AxiosRequest } from "../../../javascript/helpers/axios_helper";

export default class extends Controller {
    static values = {
        multiselect: { type: Boolean, default: false },
        updateDom: { type: String, default: '' },
        updateUrl: { type: String, default: '' }
    }

    connect() {
        this.selected = new Set()
        let _this = this;
        $(this.element).find('input:checked')
                       .each((index, checkbox) => {
            _this.selected.add($(checkbox).val())
        })
    }

    select(event) {
        // Get selectable dom and input checkbox
        let selectable_dom = $(event.currentTarget).find(event.params.selectable);
        let checkbox = selectable_dom.prev() // CAREFUL: Checkbox has to be the previous sibling element

        // If not multiselect, deselect selected item
        if (!this.multiselectValue){
            let selected_item = $(this.element).find('input:checked')
                                               .not(checkbox)
            if (selected_item) {
                selected_item.prop('checked', false)
            }
        }

        // Select dom
        // 1) Get checkbox and set to right value
        // CAREFUL: Checkbox has to be the previous sibling element
        if (checkbox.is(':checked')) {
            checkbox.prop('checked', false)
            this.selected.delete(checkbox.val())
        }
        else {
            checkbox.prop('checked', true)
            this.selected.add(checkbox.val())
        }
    }

    updateValues(event) {
        if ( this.updateUrlValue ) {
            let selectedInDomToUpdate = $(`#${this.updateDomValue}`).val();

            const data = {
                colors: [...this.selected],
                target: this.updateDomValue,
                selectedInDomToUpdate: selectedInDomToUpdate
            }

            new AxiosRequest(this.updateUrlValue, 'post', data).sendTurboStream();
        }
    }
}