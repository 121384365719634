import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        waitTime: Number,
        inputId: { type: String, default: 'query' }
    }

    static targets = ['form']

    request(_event) {
        const input = $(`[id=${this.inputIdValue}]:visible`);
        const form  = this.formTarget;

        clearTimeout(this.timer);

        this.timer = setTimeout(() => {
            form.requestSubmit();
        }, this.waitTimeValue);
    }
}