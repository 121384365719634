export function defaultTemplate(item) {
    return item.text
}

export function country(item) {
    let country = $(item.element).data('iso-code') ? $(item.element).data('iso-code')
                                                   : item.country
    let $template = $(`<span><i class='fi fi-${country} me-1'></i> ${item.text}</span>`)
    return $template
}

export function formatItem(kind) {
    switch (kind) {
        case 'country':
        case 'taster':
            return (item) => country(item);
        default:
            return (item) => defaultTemplate(item);
    }
}