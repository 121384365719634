import frenchkiss from 'frenchkiss';

frenchkiss.set('fr', {
    reports: {
        warning_new: {
            title: 'Nouveau rapport disponible',
            content: 'Vous pouvez rafraîchir la page pour voir le nouveau rapport'
        }
    }
})

frenchkiss.set('en', {
    reports: {
        warning_new: {
            title: 'New report available',
            content: 'You can refresh the page to see the new report'
        }
    },
    country: "{N, plural, one{country} other{countries}}",
    region: "region{N, plural, one{} other{s}}",
    appellation: "appellation{N, plural, one{} other{s}}",
    cepage: "cepage{N, plural, one{} other{s}}",
    taster: "taster{N, plural, one{} other{s}}",
    contest: "contest{N, plural, one{} other{s}}"
})

frenchkiss.set('es', {
    reports: {
        warning_new: {
            title: 'Nuevo informe disponible',
            content: 'Puede actualizar la página para ver el nuevo informe'
        }
    }
})

frenchkiss.set('it', {
    reports: {
        warning_new: {
            title: 'Disponibile il nuovo rapporto',
            content: 'È possibile aggiornare la pagina per visualizzare il nuovo report.'
        }
    }
})

frenchkiss.set('pt', {
    reports: {
        warning_new: {
            title: 'Novo relatório disponível',
            content: 'Pode atualizar a página para ver o novo relatório'
        }
    }
})

export default function initFrenchkiss() {
    window.t = frenchkiss.t;
    window.plural = frenchkiss.plural('en', n => {
        const i = Math.floor(Math.abs(n));
        return i === 1 ? 'one' : 'other';
      });
}