import {Controller} from '@hotwired/stimulus';
import {Popover} from 'bootstrap';

export default class extends Controller {
    static values = {
        keyTitle: { type: String, default: '' },
        keyContent: { type: String, default: '' },
    }

    connect() {
        let lang = new URL(window.location).searchParams.get('lang') || 'fr'
        let element = $(this.element);

        if (this.keyTitleValue) {
            element.attr('data-bs-title',
                         t(this.keyTitleValue, {}, lang))
        }

        element.attr('data-bs-content',
                     t(this.keyContentValue, {}, lang))

        this.popover = new Popover(this.element, {
            trigger: 'hover click'
        })
    }
}