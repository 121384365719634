import { Base } from "./base_helper"

export class Bar extends Base {
    constructor(data, filename) {
        super(750, filename)

        // Set sunburst data
        this.data = [{
            type: 'bar',
            name: data.name,
            text: data.text,
            x: data.labels,
            y: data.values,
            marker: {
                color: data.color
            }
        }]
    }
}

export class StackedBar extends Base {
    constructor(data) {
        super()
        // Set data
        console.log(data.values.forEach( (barValues, index) => {
            new Bar({ labels: data.labels, values: barValues, color: data.colors[index] }).data[0]
        } ))

        this.data = []
        data.values.forEach( (barValues, index) => {
            this.data.push(
                new Bar(
                    {
                        name: data.name[index],
                        labels: data.labels.map(label =>  `<b>${label}</b>` ),
                        values: barValues,
                        text: barValues.map(this.format_value),
                        color: data.colors[index]
                    }
                ).data[0]
            )
            barValues.map(value => this.format_value(value))}
        );


        // Stacked bar
        this.layout.content.barmode = 'relative'
        this.layout.content.yaxis = {
            domain: [0.2, 0.9],
            range: [-100, 100],
            zeroline: true,
            nticks: 1,
            tickmode: 'array',
            tickvals: [0],
            ticktext: [""]
        }

        this.layout.content.xaxis = {
            domain: [0.1, 1],
            tickfont: {
                family: 'Droid Sans',
                size: 13
            }
        }
        this.layout.content.bargap = 0.45
        this.layout.content.legend = {
            x: 0.5,
            y: 0,
            orientation: 'h',
            yanchor: 'bottom',
            xanchor: 'center'
        }
    }

    format_value(value) {
        let formated_value = value.toString().replace('-', '').trim();
        return `<b>${formated_value}%</b>`
    }
}