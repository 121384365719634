import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"
import { animateCSS } from "../../plugins/animate_css";
import { AxiosRequest } from "../../helpers/axios_helper";
import { FormCache } from "../../helpers/cache_helper";

// Connects to data-controller="components--filtering"
export default class extends Controller {
  static values = {
    cachePage: String
  }

  static targets = ['modal', 'form', 'filterIcon']

  connect(){
    this.modal = new Modal(this.modalTarget)
    this.formCache = new FormCache(this.cachePageValue);
    var _this = this;

    // Handle events
    $(this.formTarget).on('submit', function(e) {
      // Prevent to send form, save form data
      e.preventDefault()
      _this.isFormSubmit = true;
      _this.formData = new FormData(this)

      _this.formCache.cacheForm($(_this.formTarget))

      // Hide modal and submit form
      _this.modal.hide();
    });

    $(this.modalTarget).on('show.bs.modal', function (e) {
      animateCSS(_this.modalTarget, 'slideInUp', '0.5s')
    });

    this.changeIcon();
  }

  openFilterModal(event)  {
    // Open modal
    this.modal.show()
    this.isFormSubmit = false

    // Add close animation
    var _this = this;
    $(this.modalTarget).one('hide.bs.modal', function (e) {
      e.preventDefault()
      animateCSS(_this.modalTarget, 'slideOutDown', '0.5s').then((message) => {
        _this.modal.hide()

        // If comes from form submit then submit it
        if (_this.isFormSubmit) {
          new AxiosRequest(_this.formTarget.action,
                           'post',
                           _this.formData).sendTurboStream()
        }
      })
    });

  }

  changeIcon() {
    if (this.hasFilterIconTarget) {
      var activeClasses = 'fa-kit fa-sharp-regular-filter-circle-check';
      var inactiveClasses = 'fa-sharp fa-regular fa-filter-circle-xmark';
      if (this.anyActiveFilters()) {
        $(this.filterIconTarget).removeClass(inactiveClasses).addClass(activeClasses)
      }
      else {
        $(this.filterIconTarget).addClass(inactiveClasses).removeClass(activeClasses)
      }
    }
  }

  anyActiveFilters() {
    let rows = $(this.modalTarget).find('.row')
    var activeFilters = false

    rows.each((_, row) => {
      let condition =  ( $(row).find('input:checked').length > 0)
      // Has select ?
      let select_dom = $(row).find('select')

      if (select_dom.length > 0) {
        condition ||= select_dom.val().length > 0
      }

      if ( condition ) {
        activeFilters = true;
        return;
      }
    });

    return activeFilters;
  }
}
