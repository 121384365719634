import { Controller } from "@hotwired/stimulus";
import axios from 'axios'



export default class extends Controller {
    static targets = ['selectableItem', 'counter']

    static values = {
        name: { type: String, default: '' },
        checkboxName: { type: String, default: '' },
        withSearch: { type: Boolean, default: false },
        fetchIdsUrl: { type: String, default: '' },
        searchInputId: { type: String, default: '' },
        selectedItems: { type: Array, default: []}
    }

    initialize() {
        this.selectedItems = new Set(this.selectedItemsValue.map(String))
        this.updateCounter()

        let _this = this;
        $(this.element).on('submit', function(e) {
            // Intercept form submit and add selected ids
            e.preventDefault()
            _this.formData = new FormData(this)
            _this.sendForm();
        })
    }

    selectableItemTargetConnected(selectableItem) {
        const checkbox = $(selectableItem).find('.checkbox:visible')
                                          .find('input')

        if (this.selectedItems.has(checkbox.val())) {
            checkbox.prop('checked', true)
        }
    }

    toggleSelect(event) {
        const selectableItem = $(event.currentTarget)
        this.toggleSelectItem(selectableItem)
        this.updateCounter()
    }

    toggleSelectItem(selectableItem, modes = ['select', 'unselect']) {
        const checkbox = selectableItem.find('.checkbox:visible')
                                       .find('input')
        // Toggle select
        if (checkbox.is(':checked') && modes.includes('unselect')) {
            checkbox.prop('checked', false)
            this.selectedItems.delete(checkbox.val())
        }
        else if (modes.includes('select')) {
            checkbox.prop('checked', true)
            this.selectedItems.add(checkbox.val())
        }
    }

    toggleSelectResults(event) {
        if (this.withSearchValue) {
            let n_results = $('#n_results').text().match(/\d+/)[0]
            n_results = parseInt(n_results) || -1

            let selectableItemsCount = this.selectableItemTargets.length

            // Two cases:
            // 1: Number of selectable items is equal to number of results.
            //    In this case, get selectable ids on page
            let _this = this;
            let modes = [event.currentTarget.id]

            if ( selectableItemsCount == n_results ) {
                this.selectableItemTargets.forEach( (item) => _this.toggleSelectItem($(item), modes) )
                this.updateCounter()
            } else {
                this.fetchIds(modes)
            }
        }
    }

    updateCounter() {
        const selectedCount = this.selectedItems.size;
        const text = selectedCount < 2 ? 'selectionné' : 'sélectionnés'

        $(this.counterTarget).text(`${selectedCount} ${text}`)

        if (selectedCount > 0) {
            $('#unselect').addClass('cursor')
        } else {
            $('#unselect').removeClass('cursor')
        }
    }

    async randomChoice(event) {
        let nResults = $('#n_results').text().match(/\d+/)[0]

        if (nResults) {
            // Ask to user number of comments
            const { value: nComments } = await Swal.fire({
                title: "Combien de commentaires",
                icon: "question",
                input: "range",
                inputLabel: "nombre de commentaires",
                inputAttributes: {
                    min: "1",
                    max: nResults,
                    step: "1"
                },
                inputValue: Math.floor(nResults / 3),
                showClass: {
                popup: `
                    animate__animated
                    animate__fadeInUp
                    animate__faster
                `
                },
                hideClass: {
                popup: `
                    animate__animated
                    animate__fadeOutDown
                    animate__faster
                `
                }
            });

            if (nComments) {
                this.fetchIds(['select'], nComments)
            }
        }
    }

    fetchIds(modes, randomComments = null ) {
        let _this = this;

        let url = new URL(this.fetchIdsUrlValue,
                          document.location)

        if (this.withSearchValue) {
            // APPEND Query params if search is active
            const searchInput = $(this.searchInputIdValue).val();
            if (searchInput) {
                url.searchParams.append("query", searchInput)
            }
        }

        if (randomComments) {
            url.searchParams.append('n_random', randomComments)
        }

        axios.get(url)
             .then(response => {
                let data = response.data

                // CAST ids as String to be sure that comparison are on same types
                let ids = new Set([...data.ids.map(String)])
                _this.selectedItems = modes.includes('select') ? new Set([..._this.selectedItems, ...ids])
                                                               : new Set(Array.from(_this.selectedItems)
                                                                              .filter(item => !ids.has(item)))
             })
              .finally(() => {
                _this.selectableItemTargets
                     .forEach( (item) => {
                        let id = $(item).find('.checkbox:visible')
                                        .find('input')
                                        .val()
                        if (_this.selectedItems.has(id) || modes.includes('unselect')) {
                            _this.toggleSelectItem($(item), modes)
                        }
                        _this.updateCounter()
                    })
              })
    }

    sendForm() {
        let _this = this;
        Array.from(this.selectedItems)
             .forEach((item) => {
                _this.formData.append(_this.checkboxNameValue, item)
              } )

        axios.post(
            this.element.action,
            this.formData,
            {
                headers: {
                    'Accept': 'application/json'
                }
            })
             .then( response => {
                let data = response.data
                if (data.location)
                {
                    window.location = data.location
                }
            })
             .catch(error => {
                if (error.response) {
                    let data = error.response.data
                    toastr.error(data.error)
                }

             })
    }
}