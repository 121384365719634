import axios from 'axios';

export class AxiosRequest {
    constructor(url, method, data, headers) {
        this.url = url;
        this.method = method;
        this.params = null; // only used for GET requests
        this.data = null;
        this.headers = headers;
        this.csrfToken = $("meta[name='csrf-token']").attr('content');
        method === 'get' ? this.params = data : this.data = data;
    }

    sendHtml() {
        return axios({
            url: this.url,
            method: this.method,
            data: this.data,
            params: this.params,
            headers: Object.assign(
                {
                    'X-CSRF-Token': this.csrfToken,
                },
                this.headers,
            ),
        }).then(response => { return response.data });
    }

    sendTurboStream() {
        axios({
            url: this.url,
            method: this.method,
            data: this.data,
            params: this.params,
            headers: Object.assign(
                {
                    'X-CSRF-Token': this.csrfToken,
                    'Accept': 'text/vnd.turbo-stream.html'
                },
                this.headers,
                ),
        }).then(response => { return response.data })
          .then(text => Turbo.renderStreamMessage(text));
    }
}