export class TabCache {
    constructor(key) {
        this.key = key;
    }

    store() {
        sessionStorage.setItem(this.key, JSON.stringify([...this.storage]));
    }
}

export class FormCache extends TabCache {
    constructor(key) {
        super(key)
        this.storage = sessionStorage.hasOwnProperty(key) ? JSON.parse(sessionStorage.getItem(key))
                                                          : [];
    }

    set(key, value) {
        this.storage.push( { name: key, value: value} )
    }

    cacheForm(form) {
        let cached_form = form.serializeArray().filter( input => input.name != 'authenticity_token' );
        sessionStorage.setItem(this.key, JSON.stringify(cached_form))
    }
}

export class SetCache extends FormCache {
    constructor(key) {
        super(key)
        this.storage = new Set(this.storage)
    }

    add(item) {
        this.storage.add(item)
    }

    remove(item) {
        this.storage.delete(item)
    }
}