import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        fieldSelector: { type: String, default: '.nested-field' },
    }

    removeField(event) {
        const fieldsCount = $(this.fieldSelectorValue).length - 1;
        const allowEmpty = event.target.dataset.allowEmpty ?? false;

        if (fieldsCount > 1 || allowEmpty) {
            this.element.remove();
        } else {
            toastr.error('Impossible de supprimer une ligne unique');
        }
    }
}
