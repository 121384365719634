import {Controller} from '@hotwired/stimulus';
import { Popover } from 'bootstrap';

export default class extends Controller {
    static targets = ['includeTab', 'excludeTab']

    static values = {
        tabsFor: { type: String, default: '' }
    }

    connect() {
        this.tabsFor = this.tabsForValue

        // Disable Popover
        new Popover($(this.includeTabTarget)).disable()
        new Popover($(this.excludeTabTarget)).disable()
    }

    changeTab(event) {
        let tab = event.params.tab
        let target = this.tabTextToTarget(tab)

        // If tab is inactive do nothing
        if ($(target).hasClass('inactive')) { return; }

        let oppositeTarget = this.oppositeTarget(tab)

        // Remove active on target
        $(oppositeTarget).removeClass('active')
        $(target).addClass('active')

        // Toggle tabs
        this.toggle(tab)

    }

    toggle(tab) {
        let domToShow = $(`#${tab}${this.tabsFor}`)
        let domToHide = $(`#${this.oppositeTab(tab)}${this.tabsFor}`)

        domToHide.addClass('d-none')
        domToShow.removeClass('d-none')
    }

    oppositeTab(tab) {
        return tab === 'includeTab' ? 'excludeTab' : 'includeTab'
    }

    oppositeTarget(tab) {
        return this.tabTextToTarget(this.oppositeTab(tab))
    }

    tabTextToTarget(tab) {
        switch(tab) {
            case 'includeTab':
                return this.includeTabTarget
            case 'excludeTab':
                return this.excludeTabTarget
            default:
                return
        }
    }
}