export function uid() {
    return Math.floor(( 1+ Math.random() ) * 0x10000)
               .toString(16)
               .substring(1)
};

export function sum(array) {
    return array.reduce( (accumulator, a) => accumulator + a, 0 )
}

export function dispatchChanges(doms, selected_ids, data_attr) {
    for (const dom of doms) {
        $(dom).attr(data_attr, selected_ids)
    }
}

export class MSet extends Set {
    difference(s2) {
        return new MSet([...this].filter(element => !s2.has(element)))
    }
}