import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['dropdown', 'icon', 'text']
    static values = {
        display: { type: String, default: 'block' }
    }

    connect() {
    }

    toggle() {
        if (this.hasIconTarget) {
            $(this.iconTarget).toggleClass('fa-chevron-down fa-chevron-up')
        }

        if (this.hasTextTarget) {
            let btn_text = $(this.textTarget).text().trim()
            let text_plus = $(this.textTarget).data('displayTextPlus').trim()

            btn_text = btn_text == text_plus ? $(this.textTarget).data('displayTextMoins')
                                             : $(this.textTarget).data('displayTextPlus')

            $(this.textTarget).text(btn_text)
        }

        $(this.dropdownTarget).toggleClass(`d-none d-${this.displayValue}`)
    }
}