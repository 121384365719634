import {Controller} from '@hotwired/stimulus';

import { Radar } from '../helpers/plotly/radar_helper';
import { StackedBar } from '../helpers/plotly/bar_helper';
import { Pie } from '../helpers/plotly/pie_helper';
import { Sunburst } from '../helpers/plotly/sunburst_helper';
import { Gauge } from '../helpers/plotly/gauge_helper';

export default class extends Controller {
    static values = {
        data: { type: Object, default: {} },
        plotType: { type: String, default: '' },
        medalFile: { type: String, default: 'https://cmb-medals.s3.eu-west-3.amazonaws.com/logo_CMB.png?no_cache'},
        title: { type: String, default: '' },
        forInscription: { type: Boolean, default: true },
        height: { type: Number, default: 750  },
        filename: { type: String, default: 'newPlot' }
    }

    static targets = ['plot']

    connect() {
        // Wait for document to be ready before plot
        $(() => {
            try{
                this.check_kind()
                this.plot()

                // Remove download button
                $('.modebar-container').addClass('d-none')

                // Get last clicked aroma
                if (this.plotTypeValue == 'sunburst') {
                    this.watchSunburstForReset()
                }
            }
            catch(e) {
                console.log(e.message)
                $(this.element).remove()
            }
        });

    }

    plot() {
        this.plotlyPlot = this.plotObject();

        if (this.plotTypeValue == 'radar') {
            this.plotlyPlot.fillColor(this.dataValue.color)
            this.plotlyPlot.boldLabels()
            this.plotlyPlot.add_default()
        }

        if (this.forInscriptionValue) {
            this.plotlyPlot.add_image({ href: this.medalFileValue })
        }
        this.plotlyPlot.add_text( { text: this.titleValue })

        this.plotlyPlot.plot_on(this.plotTarget)
    }

    relayout() {
        Plotly.relayout(this.plotTarget,
                       { autosize: true })
    }

    check_kind() {
        if ($.inArray(this.plotTypeValue, [
            'radar',
            'sunburst',
            'pie',
            'stackedBar',
            'gauge'] ) != -1) {
            return
        }
        else {
            throw new Error("Le type de graphique n'est pas dans la liste");
        }
    }

    plotObject(){
        switch (this.plotTypeValue) {
            case 'radar':
                return new Radar(this.dataValue, this.filenameValue);
            case 'sunburst':
                return new Sunburst(this.dataValue, this.heightValue, this.filenameValue);
            case 'pie':
                return new Pie(this.dataValue, this.filenameValue);
            case 'stackedBar':
                return new StackedBar(this.dataValue, this.filenameValue);
            case 'gauge':
                return new Gauge(this.dataValue, this.filenameValue);
            default:
                console.log('Type de graphique non pris en compte');
        }
    }

    downloadPlot() {
        this.plotlyPlot.download(this.plotTarget)
    }

    watchSunburstForReset() {
        var _this = this;
        this.plotTarget.on('plotly_sunburstclick', data => {
            let currentData = data.points[0].data
            let parent = data.points[0].parent

            if( _this.last_parent === data.nextLevel) {
                currentData.level = ''
                _this.last_parent = null
                Plotly.redraw(_this.plotTarget, currentData)

                return false
            }

            _this.last_parent = data.nextLevel ? parent : _this.last_parent
        })
    }
}